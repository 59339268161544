import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// GLOBAL CUSTOM COMPONENTS

import WhiteButton from "@/components/WhiteButton";
import {H2, H3, H4, Paragraph, Span} from "@/components/Typography";
import CategoryCard1 from "@/components/category-cards/category-card-1";
// CSS ANIMATION NAME

import { AdTitle1, AdWrapper } from "./styles";
import {primary} from "@/theme/theme-colors.js";
import {Link} from "@inertiajs/react";
// CUSTOM DATA MODEL


// ===========================================================
export default function Section3({
  categories
}) {
  return <Container className="mt-4">

      <Grid container spacing={3}>
          <Grid xs={12} item style={{paddingLeft: '1.5rem'}}>
                  <H2 style={{color: primary["900"]}}>{'Featured Categories'.toUpperCase()}</H2>
                  <H4>Browse our selections</H4>
          </Grid>
          {
              /* CATEGORY LIST AREA */
          }
          {categories.map(item => <Grid item lg={2} md={3} sm={4} xs={6} key={item.id}>
              <CategoryCard1 image={`/storage/${item.image}`} title={item.name} slug={item.slug}/>
          </Grid>)}

          {
              /* ANIMATED BANNER AREA */
          }
          <Grid item xs={12}>
              <AdWrapper alignItems="center">
                  <AdTitle1>back to school sale!</AdTitle1>

                  <Paragraph fontSize={28} className="text-wrapper">
                      <Span className="slide-text">
                          Get all <Span className="slide-text-bold">your school supplies</Span>
                      </Span>
                  </Paragraph>

                  <Box padding={3} flexShrink={0} zIndex={5}>
                      <Link href={'/products'}><WhiteButton>Shop Now</WhiteButton></Link>
                  </Box>
              </AdWrapper>
          </Grid>
      </Grid>
  </Container>;
}
