// GLOBAL CUSTOM COMPONENTS
import {H4} from "@/components/Typography";
import LazyImage from "@/components/LazyImage";
// STYLED COMPONENTS

import {CategoryTitle, Wrapper} from "./styles.js";
import {Link} from "@inertiajs/react";
// ============================================================


// ============================================================
export default function CategoryCard1({
                                          image,
                                          title,
                                          slug,
                                      }) {
    return <Link href={`/categories/${slug}`}><Wrapper>
        <LazyImage src={image} width={213} height={213} alt="category"/>


        <CategoryTitle className="category-title">
            <H4>{title}</H4>
        </CategoryTitle>
    </Wrapper>
    </Link>;
}
